import React, {FC, useState} from 'react';
import {AppBar, Box, Container, Drawer, IconButton, List, ListItem, ListItemText, Toolbar} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link } from 'react-router-dom';
import Logo from './Logo-01.png'

interface Props {}

const CustomAppBar: FC<Props> = (props) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleNavigation = (path: string) => {
        navigate(path);
        setIsDrawerOpen(false);
    };

    return (
        <Box>
            <AppBar position="fixed">
                <Container maxWidth={'sm'}>
                    <Toolbar sx={{ justifyContent: 'center', position: 'relative' }}>
                        <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ position: 'absolute', left: 16 }}>
                            <MenuIcon />
                        </IconButton>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <img src={Logo} alt="Logo" style={{ height: '50px', width: 'auto' }} />
                        </Link>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerToggle}>
                <List>
                    <ListItem onClick={() => handleNavigation('/')} sx={{cursor: 'pointer'}}>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem onClick={() => handleNavigation('/ai-predictions')} sx={{cursor: 'pointer'}}>
                        <ListItemText primary="Predictions" />
                    </ListItem>
                    <ListItem onClick={() => handleNavigation('/about')} sx={{cursor: 'pointer'}}>
                        <ListItemText primary="About" />
                    </ListItem>
                </List>
            </Drawer>
        </Box>
    )
}

export default CustomAppBar