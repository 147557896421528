import {Box, Link, Typography} from "@mui/material";


export  const AboutPage = () => {

    return (
        <Box>
            <Typography variant="body1" mb={2}>
                PhilthyDimes is a startup founded by Nick Chiafullo, dedicated to applying AI and machine learning concepts to sports data for daily stat projections and game recaps. With over 15 years of experience in software engineering, Nick has a passion for statistics and technology, which inspired the creation of PhilthyDimes. The site was launched in March 2024, and while we're early on, expect to see new features pushed out almost daily. Right now, the focus is on the NBA, but we'll cover the NFL, MLB, and NHL in the very near future.
            </Typography>
            <Typography variant="body1">
                We promise never to push sportsbooks offers, sell picks, or engage in other money-grab tactics. This site is
                designed to showcase the technical skills of Nick Chiafullo and his business,{' '}
                <Link href="https://nlc.consulting" color="orange" underline="hover">NLC Consulting</Link>. If you're looking to apply AI concepts to your business or have any suggestions or improvements for the site, please{' '}
                <Link href="mailto:nick@nlc.consulting" color="orange" underline="hover">contact Nick.</Link>
            </Typography>
        </Box>

    )
}

export default AboutPage