import React, { useState } from 'react';
import {
    Box,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Table,
    Paper,
    TableSortLabel
} from '@mui/material';
import { IPlayerPrediction } from './NBAPlayerPredictions';

interface Props {
    predictionData: IPlayerPrediction[];
}

type Order = 'asc' | 'desc';

const PlayersPredictionTable: React.FC<Props> = ({ predictionData }) => {
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof IPlayerPrediction>('prediction_pts');

    const handleSort = (property: keyof IPlayerPrediction) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = [...predictionData].sort((a, b) => {
        if (orderBy === 'player_name') {
            return order === 'asc' ? a.player_name.localeCompare(b.player_name) : b.player_name.localeCompare(a.player_name);
        } else {
            const aValue = a[orderBy] as number;
            const bValue = b[orderBy] as number;
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        }
    });

    return (
        <Box sx={{ overflowX: 'auto' }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'player_name'}
                                    direction={orderBy === 'player_name' ? order : 'asc'}
                                    onClick={() => handleSort('player_name')}
                                >
                                    Player
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ minWidth: '120px' }}>
                                <TableSortLabel
                                    active={orderBy === 'prediction_pts'}
                                    direction={orderBy === 'prediction_pts' ? order : 'asc'}
                                    onClick={() => handleSort('prediction_pts')}
                                >
                                    Proj. Pts
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ minWidth: '120px' }}>
                                <TableSortLabel
                                    active={orderBy === 'prediction_ast'}
                                    direction={orderBy === 'prediction_ast' ? order : 'asc'}
                                    onClick={() => handleSort('prediction_ast')}
                                >
                                    Proj. Ast
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ minWidth: '120px' }}>
                                <TableSortLabel
                                    active={orderBy === 'prediction_trb'}
                                    direction={orderBy === 'prediction_trb' ? order : 'asc'}
                                    onClick={() => handleSort('prediction_trb')}
                                >
                                    Proj. Reb
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>L5</TableCell>
                            <TableCell>L5 Against</TableCell>
                            <TableCell>MAPE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.player_name}</TableCell>
                                <TableCell align="center">{row.prediction_pts}</TableCell>
                                <TableCell align="center">{row.prediction_ast}</TableCell>
                                <TableCell align="center">{row.prediction_trb}</TableCell>
                                <TableCell>{row.display_player_stat_line}</TableCell>
                                <TableCell>{row.display_against_stat_line}</TableCell>
                                <TableCell>{row.pd_accuracy}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default PlayersPredictionTable;
