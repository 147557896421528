import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Divider, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayerPredictionCard from './PlayerPredictionCard';

interface ISummaryLast5 {
    mp: string
    ast: string
    pts: string
    trb: string
}

interface INbaAiSummary {
    team: string
    last5: ISummaryLast5
    player: string
    prediction: string
    last5Against: ISummaryLast5
    predictionStatLine: string
}

interface IGamePlayer {
    id: number
    game_date: string
    home_team: string
    away_team: string
    player_name: string
    player_id: number
    player_team: string
    prediction_pts: number
    prediction_ast: number
    prediction_trb: number
    display_player_stat_line: string
    display_against_stat_line: string
    display_rw_projection_stat_line: string
    pd_accuracy: string
    rw_accuracy: string
    summary: INbaAiSummary
}

interface IGameSummary {
    game: string
    home_team_name: string
    away_team_name: string
    home_team_players: IGamePlayer[]
    away_team_players: IGamePlayer[]

}

interface Props {
    games: IGameSummary[];
}

const PlayersPredictionAccordion: React.FC<Props> = ({ games }) => {
    return (
        <Box sx={{backgroundColor: '#313131'}}>
            {games.map((game, index) => (
                <>
                    <Box key={index} sx={{marginBottom: 4}}>
                        <Typography variant="h6" sx={{color: 'white', marginBottom: 2}}>
                            {game.away_team_name} @ {game.home_team_name}
                        </Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`panel${index}b-content`}
                                              id={`panel${index}b-header`}>
                                <Typography sx={{color: 'white'}}>{game.away_team_name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <PlayerPredictionCard teamName={game.away_team_name} players={game.away_team_players}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`panel${index}a-content`}
                                              id={`panel${index}a-header`}>
                                <Typography sx={{color: 'white'}}>{game.home_team_name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <PlayerPredictionCard teamName={game.home_team_name} players={game.home_team_players}/>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    <Divider sx={{ my: 2, bgcolor: 'gray.700' }} />
                </>
            ))}
        </Box>
    );
};

export default PlayersPredictionAccordion;
