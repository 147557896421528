import NbaGameRecaps from "../components/NBA/NbaGameRecaps";
import {Box, Typography} from "@mui/material";
import {pd_orange} from "../constants";

export  const GameRecapPage = () => {

    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Typography color={pd_orange} variant="h5" gutterBottom>
                    Yesterday in the NBA
                </Typography>
            </Box>
            <NbaGameRecaps hideSummary={true} />
        </>
    )
}

export default GameRecapPage