import React, { useEffect, useState } from 'react';
import { Typography, Box, Divider } from '@mui/material';
import ExpandableTextCard from '../common/ExpandableTextCard';
import {IGameRecap, IGameSummary} from "../../../types";

interface Props {
    hideSummary?: boolean | undefined
}

const NbaGameRecaps: React.FC<Props> = ({hideSummary}) => {
    const [gameData, setGameData] = useState<IGameRecap | undefined>(undefined)

    useEffect(() => {
        fetch('/api/ai/summaries').then(r => r.json()).then(r => setGameData(r))
    }, []);

    const shouldHideSummary = !!hideSummary

    if(!gameData){
        return null
    }

    const {day_summary, game_summaries} = gameData

    const getGameTitle = (game: IGameSummary) => {
        return `${game.home_team} (${game.home_team_score}) vs ${game.away_team} (${game.away_team_score})`
    }

    return (
        <Box sx={{ backgroundColor: '#313131', color: 'white' }}>

            {!shouldHideSummary && (
                <>
                    <Typography variant="h5" sx={{ color: 'white', marginBottom: 1 }}>
                        {day_summary.recap_title}
                    </Typography>
                    <ExpandableTextCard
                        maxHeightPx={'250px'}
                        key={day_summary.id}
                        body={day_summary.recap_text}
                        backgroundColor={'#424242'} />

                    <Divider  sx={{marginY: 3}}/>
                </>
            )}

            { game_summaries.map((game) => (
                <ExpandableTextCard key={game.id} title={getGameTitle(game)} body={game.recap_text} />
            ))}
        </Box>
    );
};

export default NbaGameRecaps;
