import {Box, Typography} from "@mui/material";
import GameDaySummary from "../components/common/GameDaySummary";
import {pd_orange} from "../constants";
import React from "react";
import NBAPlayerPredictions from "../components/NBA/NBAPlayerPredictions";

export  const HomePage = () => {

    return (
        <Box>
            <Box sx={{ marginBottom: 2, color: 'white' }}>
                <Typography variant="body1" style={{fontSize: '0.8rem'}}>
                    Welcome to PhilthyDimes. We're currently in beta, offering AI-powered sports predictions using machine learning.
                </Typography>
            </Box>
            {/*<GameDaySummary/>*/}
            <Box>
                <Typography variant="h6" sx={{ color: pd_orange, marginBottom: 1 }}>
                    Today's Projection
                </Typography>
                <NBAPlayerPredictions />
            </Box>
        </Box>
    )
}

export default HomePage