import React, {FC, useEffect, useState} from "react";
import {Box} from "@mui/material";
import PlayersPredictionAccordion from "./PlayersPredictionAccordion";
import PlayersPredictionTable from "./PlayersPredictionTable";

export interface IPlayerPrediction {
    id: number
    game_date: string
    home_team: string
    away_team: string
    player_name: string
    player_id: number
    player_team: string
    summary: string | null
    prediction_pts: number
    prediction_ast: number
    prediction_trb: number
    display_player_stat_line: string
    display_against_stat_line: string
    display_rw_projection_stat_line: string
    display_pd_projection_stat_line: string
    created_at: string
    updated_at: string
    pd_accuracy: string
    rw_accuracy: string
}

interface Props {}

const NBAPlayerPredictions: FC<Props> = () => {
    const [isCardView, setIsCardView] = useState<boolean>(false)
    const [predictData, setPredictData] = useState([])
    const [tabularData, setTabularData] = useState<IPlayerPrediction[]>([])

    useEffect(() => {
        // commented out: ?game_date=3/2/2024 (pulling from db when null)
        fetch('/api/ai/predictions').then(r => r.json()).then(r => {
            // setPredictData(r)
            console.log('l', r.length)
            setTabularData(r)
        })
    }, []);

    return (
        <Box>
            {!isCardView && (
                <PlayersPredictionTable predictionData={tabularData}></PlayersPredictionTable>
            )}
            {isCardView && (
                <PlayersPredictionAccordion games={predictData}/>
            )}
        </Box>
    )
}


export default NBAPlayerPredictions