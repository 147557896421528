import React, {FC, useState} from 'react';
import { Card, CardContent, Typography, Button, CardHeader } from '@mui/material';
import {renderFormattedText} from "../../renderUtils";

interface Props {
    title?: string | undefined
    maxHeightPx?: string | undefined
    body: string
    backgroundColor?: string | undefined
}

const ExpandableTextCard: FC<Props> = ({ title, body, backgroundColor, maxHeightPx }) => {
    const [expanded, setExpanded] = useState(false);
    const bgColor = backgroundColor ?? 'black'
    const maxHeight = maxHeightPx ?? '100px'

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ marginBottom: 2, backgroundColor: bgColor }}>
            {title && (
                <CardHeader
                    title={title}
                    titleTypographyProps={{ variant: 'h6', color: 'white' }}
                    sx={{ backgroundColor: '#424242', marginBottom: 1 }}
                />
            )}
            <CardContent>
                <div style={{ maxHeight: expanded ? 'none' : maxHeight, overflow: 'hidden' }}>
                    {renderFormattedText(body)}
                </div>
                <Button onClick={toggleExpanded} sx={{ color: '#e06831', marginTop: 1 }}>
                    {expanded ? 'Read Less' : 'Read More'}
                </Button>
            </CardContent>
        </Card>

    );
};

export default ExpandableTextCard;
