import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import {pd_orange, pd_orange_light, pd_stats_label_gray} from "../../constants";

interface IPlayer {
    player_id: number
    player_name: string
    summary: {
        last5: {
            pts: string;
            ast: string;
            trb: string;
        };
        last5Against: {
            pts: string;
            ast: string;
            trb: string;
        };
        predictionStatLine: string;
        prediction: string;
    }
    prediction_pts: number
    prediction_ast: number
    prediction_trb: number
    display_player_stat_line: string
    display_against_stat_line: string
    display_rw_projection_stat_line: string
    pd_accuracy: string
    rw_accuracy: string
}

interface PlayerPredictionsProps {
    teamName: string
    players: IPlayer[]
}

const PlayerPredictionCard: React.FC<PlayerPredictionsProps> = ({ teamName, players }) => {
    return (
        <Box sx={{ marginBottom: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', fontSize: '1.1rem'  }}>
                {teamName} Player Predictions
            </Typography>
            {players.map((player) => (
                <Card key={player.player_id} sx={{ marginBottom: 2, backgroundColor: 'black', color: 'white' }}>
                    <CardContent>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: pd_orange_light, fontSize: '1.1rem'  }}>
                            {player.player_name}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '1.0rem' }}>
                            <span style={{color: pd_stats_label_gray, fontWeight: 'bold'}}>Last 5:</span> {player.display_player_stat_line}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '1.0rem' }}>
                            <span style={{color: pd_stats_label_gray, fontWeight: 'bold'}}>Last 5 vs Team:</span> {player.display_against_stat_line}
                        </Typography>

                        <Typography variant="body2" sx={{ marginTop: 1, fontSize: '1.0rem' }}>
                            <Typography component={'span'} sx={{ color: '#009dff', fontWeight: 'bold' }}>RotoWire:</Typography> {player.display_rw_projection_stat_line}
                            <br />
                            <Typography component={'span'} sx={{ color: '#009dff', fontWeight: 'bold' }}>Accuracy:</Typography> {player.rw_accuracy}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: 1, fontSize: '1.0rem' }}>
                            <Typography component={'span'} sx={{ color: '#e06831', fontWeight: 'bold' }}>PhilthyDimes:</Typography> {player.prediction_pts}p/{player.prediction_ast}a/{player.prediction_trb}r
                            <br />
                            <Typography component={'span'} sx={{ color: '#e06831', fontWeight: 'bold' }}>Accuracy:</Typography> {player.pd_accuracy}
                        </Typography>




                    </CardContent>
                </Card>
            ))}
        </Box>

    );
};

export default PlayerPredictionCard;
