import {Typography} from "@mui/material";
import React from "react";

export const renderFormattedText = (text: string) => {
    return text.split('\n').map((line, index) => {
        // Remove asterisks used for bold formatting
        const cleanedLine = line.replace(/\*\*/g, '');

        return (
            <Typography key={index} variant="body2" sx={{ marginBottom: 1 }}>
                {cleanedLine}
            </Typography>
        );
    });
};
