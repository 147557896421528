import React, { FC } from "react";
import { LayoutProps } from "react-admin";
import CustomAppBar from "./CustomAppBar";
import {Box, Container, Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CustomLayout: FC<LayoutProps> = ({ children}) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'background.default' }}>
            <CustomAppBar />
            <Container maxWidth={'sm'}>
                <Box id={'pd-root'} my={7} p={2}>
                    {children}
                </Box>
            </Container>
            <Fab color="primary" size="small" onClick={scrollToTop} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                <KeyboardArrowUpIcon />
            </Fab>
        </Box>
    )
}

export default CustomLayout