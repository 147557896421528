import React, { FC } from "react";
import {Box, Typography} from "@mui/material";
import NBAPlayerPredictions from "../components/NBA/NBAPlayerPredictions";
import {pd_orange} from "../constants";

interface Props {}

const NBAPredictPage: FC<Props> = () => {

    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Typography color={pd_orange} variant="h5" gutterBottom>
                    Today's Projections (Beta)
                </Typography>
            </Box>
            <NBAPlayerPredictions />
        </>
    )
}

export default NBAPredictPage