import React from "react";
import { Admin, CustomRoutes, defaultDarkTheme, defaultLightTheme } from "react-admin";
import { Route } from 'react-router-dom'

import CustomLayout from "./components/layout/CustomLayout";

import NBAPredictPage from "./pages/NBAPredictPage";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import GameRecapPage from "./pages/GameRecapPage";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
    return (
        <Admin layout={CustomLayout} defaultTheme={'dark'} lightTheme={defaultLightTheme} darkTheme={defaultDarkTheme}>
            <CustomRoutes>
                <Route path={'/'} element={<HomePage />} />
                <Route path={'/game-recaps'} element={<GameRecapPage />} />
                <Route path={'/about'} element={<AboutPage />} />
                <Route path={'/ai-predictions'} element={<NBAPredictPage/>} />
                {/*<Route path={'/previous-results'} element={<3/>} />*/}
            </CustomRoutes>
        </Admin>
    )
}

export default App;
